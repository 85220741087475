
<template>
  <div>
    <section v-if="!loading">
      <v-sheet color="white" class="pa-10 rounded20">
        <h2 class="overline">Filtrar facturas de cuenta corriente</h2>
        <v-select
          prepend-icon="mdi-format-list-bulleted-type"
          :items="types"
          label="Letra"
          clearable
          v-model="type"
        />
        <v-text-field
          label="Lote"
          prepend-icon="mdi-ballot"
          v-model="lot"
          clearable
        />
        <v-text-field
          label="Numero"
          prepend-icon="mdi-barcode"
          v-model="code"
          clearable
        />
        <v-menu
          ref="menu2"
          v-model="datePickerMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="termDate"
              label="Fecha"
              prepend-icon="mdi-calendar"
              v-bind="attrs"
              v-on="on"
              clearable
            />
          </template>
          <v-date-picker locale="es-es" v-model="termDate" range />
        </v-menu>
         <v-checkbox
          label="Sólo facturas vencidas"
          v-model="due"
          clearable
        />
        <v-spacer></v-spacer>
        <v-row justify="end">
          <v-btn text @click="$emit('clean')"> Limpiar filtros </v-btn>
          <v-btn
            rounded
            outlined
            color="teal"
            @click="onFilter(), (datePickerMenu = false)"
          >
            Filtrar
          </v-btn>
        </v-row>
      </v-sheet>
    </section>
    <v-skeleton-loader v-else type="card" />
  </div>
</template>

<script>
export default {
  name: "CurrentAccountFilter",

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    type: null,
    lot: null,
    code: null,
    due: false,
    termDate: null,
    datePickerMenu: false,
    types: ["A", "NA"],
  }),

  methods: {
    onFilter() {
      this.$emit("filter", {
        type: this.type,
        lot: this.lot,
        code: this.code,
        due: this.due,
        dates: this.setDates(),
      });
    },

    setDates() {
      if (this.termDate == null) return null;
      return {
        fromDate: this.termDate[0],
        toDate: this.termDate[1],
      };
    },
  },
};
</script>
