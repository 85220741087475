<template>
  <div>
    <v-row>
      <v-col sm="12" md="12" xl="3">
        <CurrentAccountFilter
          :loading="loading"
          @filter="filterInvoices($event)"
          @clean="(filter = {}), getInvoices(), (currentPage = 1)"
        />
      </v-col>
      <v-col sm="12" md="12" xl="9">
        <v-sheet color="white" class="pa-10 rounded20" v-if="invoices">
          <v-row>
            <v-col>
              <v-card class="pa-2 rounded20" color="teal lighten-1" dark>
                <v-card-title>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="40" v-text="'mdi-currency-usd'" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="balance"
                        v-text="`$ ${formatPrice(balance)}`"
                      />
                      <v-progress-linear
                        v-else
                        indeterminate
                        color="white"
                      ></v-progress-linear>
                      <v-list-item-subtitle v-text="'Saldo de cuenta'" />
                    </v-list-item-content>
                  </v-list-item>
                </v-card-title>
              </v-card>
            </v-col>
            <v-col>
              <v-card class="pa-2 rounded20" color="teal " dark>
                <v-card-title>
                  <v-list-item>
                    <v-list-item-avatar>
                      <v-icon size="40" v-text="'mdi-currency-usd-off'" />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title
                        v-if="balanceDue"
                        v-text="`$ ${formatPrice(balanceDue)}`"
                      />
                      <v-progress-linear
                        v-else
                        indeterminate
                        color="white"
                      ></v-progress-linear>
                      <v-list-item-subtitle v-text="'Saldo vencido'" />
                    </v-list-item-content>
                  </v-list-item>
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
          <v-row class="pa-5">
            <v-chip v-if="updatedAt"
              >Saldo actualizado ({{ balanceUpdatedAt }})
            </v-chip>
            <v-skeleton-loader type="chip" v-else />
            <v-spacer />
          </v-row>
          <CurrentAccountTable
            :items="computedInvoices"
            :total="count"
            :loading="loading"
            :properties="tableProperties"
            :buttons="buttomOptions"
            cardTitle="Cuenta corriente"
            :currentPage="currentPage"
            @detail="detail($event.id)"
            @pagination="pageChange($event)"
          />
        </v-sheet>
      </v-col>
    </v-row>
    <SaleInvoiceDetail />
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { eventBus } from "../../services/eventBus";
import { format } from "date-fns";

import CurrentAccountFilter from "../../components/pharmacy/CurrentAccountFilterComponent.vue";
import CurrentAccountTable from "../../components/pharmacy/CurrentAccountTableComponent.vue";
import SaleInvoiceDetail from "../../components/pharmacy/SaleInvoiceDetailComponent.vue";

export default {
  name: "Account",

  components: {
    CurrentAccountFilter,
    CurrentAccountTable,
    SaleInvoiceDetail,
  },

  data: () => ({
    currentPage: 1,
    due: false,
    dueInvoices: [],
    tableProperties: [
      {
        key: "date",
        title: "Fecha",
      },
      {
        key: "voucher",
        title: "Comprobante",
      },
      {
        key: "purchases",
        title: "Monto",
        prepend: "$",
      },
      {
        key: "payments",
        title: "Pagos",
        prepend: "$",
      },
      {
        key: "expiration",
        title: "Vencimiento",
      },
      {
        key: "saleTerm",
        title: "Plazo de venta",
      },
      {
        key: "status",
        title: "Estado",
      },
    ],
    buttomOptions: [
      {
        emit: "detail",
        name: "Detalle",
      },
    ],
    filter: null,
  }),

  computed: {
    ...mapGetters("pharmacy", ["pharmacy"]),
    ...mapGetters("currentAccount", [
      "invoices",
      "loading",
      "count",
      "balance",
      "balanceDue",
      "updatedAt",
    ]),
    balanceUpdatedAt: function () {
      return format(new Date(this.updatedAt), "dd/MM HH:mm");
    },
    computedInvoices: function () {
      return !this.due ? this.invoices : this.dueInvoices;
    },
  },
  mounted() {
    this.getInvoices();
    this.getBalances();
  },

  methods: {
    ...mapActions("currentAccount", [
      "getInvoices",
      "getBalances",
    ]),
    detail(id) {
      eventBus.$emit("saleInvoiceDetail", id);
    },
    pageChange(page) {
      this.currentPage = page;
      this.getInvoices({ page: this.currentPage, ...this.filter });
    },
    filterInvoices(data) {
      this.filter = data;
      this.getInvoices({ ...data });
    },
    formatPrice(price) {
      if(price)
        return Number(`${price}`.replace(",", ".")).toFixed(2).replace(".", ",").replace(/\d(?=(\d{3})+(?!\d))/g, "$&.")
    }
  },
};
</script>
