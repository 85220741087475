<template>
  <div>
    <v-dialog v-model="showDialog" max-width="800px">
      <v-card
        v-if="!singleLoading && saleInvoice"
        style="border-radius: 20px"
        class="pa-5"
      >
        <v-card-title class="justify-center">
          Detalle de la factura {{ saleInvoice.voucher }}
        </v-card-title>
        <v-card-text>
          <BasicTable
            :items="saleInvoice.items"
            :propeties="tablePropeties"
            :loading="singleLoading"
            :itemsPerPage="10"
            cardTitle="Artículos"
          />
        </v-card-text>
      </v-card>
      <v-card v-else color="teal" class="py-2" dark>
        <v-card-text>
          Cargando detalle de la factura...
          <v-progress-linear indeterminate color="white"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { eventBus } from "../../services/eventBus";

import BasicTable from "../../components/shared/BasicTableComponent.vue";

export default {
  components: {
    BasicTable,
  },

  name: "SaleInvoiceDetail",

  data: () => ({
    showDialog: false,
    tablePropeties: [
      {
        key: "quantity",
        title: "Cantidad",
      },
      {
        key: "code",
        title: "Codigo",
      },
      {
        key: "description",
        title: "Descripcion",
      },
      {
        key: "unitPrice",
        title: "P. Unitario",
      },
      {
        key: "saleTerm",
        title: "Plazo de venta",
      },
      {
        key: "amount",
        title: "Importe",
      },
    ],
  }),

  mounted() {
    eventBus.$on("saleInvoiceDetail", (id) => {
      this.showDialog = true
      this.getSaleInvoiceDetail(id)
    })
  },

  computed: {
    ...mapGetters("currentAccount", ["saleInvoice", "singleLoading"]),
  },

  methods: {
    ...mapActions("currentAccount", ["getSaleInvoiceDetail"]),
  },
};
</script>
